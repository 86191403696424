<template>
  <div class="fsb video_titles">
    <span class="title_left">{{ title }}</span>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'videoTitles',
  props: ['title']
};
</script>

<style scoped lang="scss">
.video_titles {
  position: relative;
  height: 72px;
  padding-left: 40px;
  border-bottom: 10px solid #eef2f5;
  .title_left {
    font-size: 24px;
    font-weight: bold;
  }
}
</style>
