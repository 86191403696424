<template>
  <el-pagination @current-change="handleCurrentChange" :pager-count="5" :current-page.sync="pageTabel.pageNum" layout="total, prev, pager, next" :total="pageTabel.total" :page-size="pageTabel.pageSize"></el-pagination>
</template>

<script>
export default {
  name: 'pagination',
  props: {
    pageTabel: {
      type: Object
    }
  },
  data() {
    return {
      pagingObj: {
        pageNum: 1
      }
    };
  },
  methods: {
    handleCurrentChange(val) {
      this.pagingObj.pageNum = val;
      this.$emit('pagingChange', this.pagingObj);
    }
  }
};
</script>

<style lang="scss">
.el-pagination {
  padding: 20px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  .total {
    padding-left: 10px;
  }
  .el-input,
  .el-pagination__sizes,
  .el-input__inner {
    height: 54px !important;
  }
  .btn-next,
  .btn-prev,
  .el-pager li,
  button:disabled {
    width: 49px;
    line-height: 54px;
    height: 54px;
    background-color: #e8e8e8;
    margin-right: 14px;
    border-radius: 6px;
    border: 1px solid #c5c5c5;
  }
  .el-pager {
    li.active + li {
      border-left: 1px solid #c5c5c5;
    }
  }

  & > span,
  .el-pager li,
  .btn-next .el-icon,
  .el-input__inner,
  .el-input__icon,
  .btn-prev .el-icon {
    color: #212121;
    font-size: 20px !important;
  }
  .el-select .el-input {
    width: 120px;
  }
  & > button:disabled {
    color: #939dab;
  }
  .el-pager li.active {
    background-color: #e3eed3;
    color: #31ac17;
  }
}
.el-select-dropdown__item {
  color: #212121;
  font-size: 20px;
}
</style>
