<template>
  <div class="collectionAdmin">
    <div class="collectionAdmin_top">
      <VideoTitle title="查看合集"></VideoTitle>
    </div>
    <div class="flex adminVideo_conter">
      <span class="tab">状态：</span>
      <ul class="tab-tilte">
        <!-- 方法一： class对象-->
        <!-- <li v-for="(title,index) in tabTitle" @click="cur=index" :class="{active:cur==index}">{{title}}</li> -->

        <!-- 方法二： class数组+三元运算-->
        <li
          v-for="(title, index) in tabTitle"
          :key="index"
          @click="check(index)"
          :class="[cur == index ? 'active' : '']"
        >
          {{ title }}
        </li>
      </ul>
    </div>
    <div v-if="listDataAll && listDataAll.length">
      <div v-for="(item, index) in listDataAll" :key="index" class="flex collectionAdmin_conter">
        <div class="left_img" @click="clickListVideo(item.videoTitle, item.videoEditingPlayUrl,item.videoState)">
          <el-image :src="item.videoEditingImg" class="noVideo"></el-image>
          <!-- <img :src="item.videoEditingImg" /> -->
          <span>{{ secondToTimeStr(item.videoEditingDuration) }}</span>
        </div>
        <div class="column right">
          <div class="right_top">
            <div class="right_tltle">{{ item.videoTitle }}</div>
            <div class="flex icon_text">
              <div class="fac">
                <img src="../../assets/img/creatorPlatform/suspend.png" />
                <span>{{ item.videoPlayNum }}</span>
              </div>
              <div class="fac">
                <img src="../../assets/img/creatorPlatform/like.png" />
                <span>{{ item.videoLikeNum }}</span>
              </div>
              <div class="fac">
                <img src="../../assets/img/creatorPlatform/info.png" />
                <span>{{ item.comment }}</span>
              </div>
              <div class="fac">
                <img src="../../assets/img/creatorPlatform/data.png" />
                <span>{{ item.giveReward }}</span>
              </div>
            </div>
            <span>发布时间:{{ item.videoCreateTime }}</span>
            <div class="state">
              视频状态：
              <span>{{ videoState(item.videoState) }} {{ item.statesDescribe }}</span>
            </div>
          </div>
          <div class="flex right_botton">
            <div class="fcc" @click="editVideo(item)">
              <img src="../../assets/img/creatorPlatform/eidt.png" />
              <span>编辑视频</span>
            </div>
            <div class="fcc" @click="addQuestions(item)">
              <img src="../../assets/img/creatorPlatform/ask.png" />
              <span>添加提问</span>
            </div>
            <!-- <div class="fcc" @click="moveVideo(index)">
            <img src="../../assets/img/creatorPlatform/move.png" />
            <span>移动视频</span>
            </div>-->
          </div>
        </div>
      </div>
      <Pagination :pageTabel="listQuery" @pagingChange="pagingChange"></Pagination>
    </div>
    <el-empty v-else :image-size="200" description="暂无数据"></el-empty>
    <el-dialog v-if="dialogVisible" :title="dataListVideo.videoTitle || '视频'" :visible.sync="dialogVisible" class="video_dialog" :append-to-body="true">
      <video controls autoplay :src="dataListVideo.videoPlayUrl"></video>
    </el-dialog>
  </div>
</template>

<script>
import VideoTitle from '@/components/public/VideoTitle.vue';
import { selectVideosBy } from '@/utils/serveApi';
import Pagination from '@/components/public/Pagination.vue';
import { videoState, secondToTimeStr, handleImage, videoStates } from '@/utils/public';
export default {
  name: 'collectionAdmin',
  data() {
    return {
      activeIndex: 0,
      inputSearch: '',
      listDataAll: [],
      dialogVisible: false,
      dataListVideo: {},
      listQuery: {
        compilationsId: this.$route.query.compilationsId,
        pageNum: 1,
        pageSize: 6,
        total: 0,
        type:0,
      },
      tabTitle: ["全部", "已通过", "未通过", "审核中", "已下架"],//, "草稿"
      cur: 0, //默认选中第一个tab
    };
  },
  created() {
    this.getListData();
  },
  methods: {
    check: function (index) {
      this.cur = index;
      switch (index) {
        case 0:
          this.listQuery.type = 0;
          break;
        case 1:
          this.listQuery.type = 2;
          break;
        case 2:
          this.listQuery.type = 3;
          break;
        case 3:
          this.listQuery.type = 1;
          break;
        case 4:
          this.listQuery.type = 4;
          break;
        default:
          this.listQuery.type = 5;
          break;
      }
      this.listQuery.pageNum = 1;
      this.getListData();
    },
    getListData() {
      selectVideosBy(this.listQuery).then(res => {
        const { video, total } = res.data;
        const newArr = video.map(item => {
          const { videoEditingImg, videoEditingPlayUrl } = item;
          if (videoEditingImg) return item;
          if (videoEditingPlayUrl) {
            handleImage(videoEditingPlayUrl, img => (item.videoEditingImg = img));
            return item;
          }
        });
        this.listDataAll = newArr;
        this.listQuery.total = total;
      });
    },
    pagingChange(o) {
      this.listQuery.pageNum = o.pageNum;
      this.getListData();
    },
    editVideo({ videoState, videoId }) {
      if (videoState != 1) {
        this.$message(videoStates[videoState]);
        return;
      }
      this.$router.push({
        name: 'postVideo',
        query: { videoId }
      });
    },
    addQuestions(item) {
      this.$router.push({
        name: 'addQuestions',
        query: {
          videoId: item.videoId
        }
      });
    },
    videoState(index) {
      return videoState(index);
    },
    secondToTimeStr(t) {
      return secondToTimeStr(t);
    },
    clickListVideo(title, url,state) {
      if(state==0 || state==3){
        this.$message("视频审核中...");
        return;
      }
      if (!url) return;
      this.dataListVideo.videoTitle = title || '视频';
      this.dataListVideo.videoPlayUrl = url;
      this.dialogVisible = true;
    }
  },
  components: { VideoTitle, Pagination }
};
</script>
<style lang="scss">
.collectionAdmin {
  &_top {
    .el-input {
      .el-input__inner {
        background: #eef2f5;
        border: 1px solid #c1c1c1;
        border-radius: 22px;
      }
    }
  }
}
.tab {
  padding-top: 10px;
  width: 10%;
  padding-left:40px;
  font-weight: bold;
}

ul,
li {
  margin: 0;
  padding: 0;
  list-style: none;
}

.tab-tilte {
  width: 100%;
}

.tab-tilte li {
  float: left;
  width: 10%;
  padding: 10px 0;
  text-align: center;
  cursor: pointer;
}

/* 点击对应的标题添加对应的背景颜色 */
.tab-tilte .active {
  //background-color: #09f;
  color: rgb(192, 158, 7);
}
</style>
<style lang="scss" scoped>
.collectionAdmin {
  &_top {
    .el-input {
      width: 574px;
      height: 40px;
      margin-right: 40px;
    }
  }
  &_conter {
    padding: 38px 40px;
    .left_img {
      width: 320px;
      margin-right: 24px;
      position: relative;
      img {
        width: 100%;
        height: 200px;
        border-radius: 10px;
      }
      span {
        position: absolute;
        bottom: 16px;
        right: 16px;
        width: 52px;
        height: 26px;
        line-height: 26px;
        text-align: center;
        display: block;
        background: #9a9a9a;
        border-radius: 4px;
        font-size: 14px;
      }
      .noVideo {
        height: 200px;
        border-radius: 10px;
        object-fit: cover;
        cursor: pointer;
      }
    }
    .right {
      &_tltle {
        font-size: 20px;
        color: #000000;
      }
      &_top {
        color: #999999;
        .icon_text {
          margin: 10px 0;
          img {
            width: 18px;
            height: 18px;
          }
          span {
            font-size: 18px;
            padding: 0 20px 0 8px;
          }
        }
        & > span {
          font-size: 16px;
        }
        .state {
          margin: 10px 0;
          font-size: 16px;
          & > span {
            color: #ff0000;
          }
        }
      }
      &_botton {
        margin-bottom: 5px;
        & > div {
          width: 118px;
          height: 36px;
          background: #eef2f5;
          border-radius: 21px;
          cursor: pointer;
          margin-right: 24px;
          img {
            width: 16px;
            height: 16px;
          }
          span {
            font-size: 16px;
            color: #222222;
            line-height: 0;
            padding-left: 6px;
          }
        }
      }
    }
  }
}
.video_dialog {
  ::v-deep .el-dialog__title,
  ::v-deep .el-icon-close {
    font-size: 20px;
  }
  ::v-deep .el-dialog {
    width: 60%;
    height: 600px;
    border-radius: 0;
    margin-top: 10vh !important;
    .el-dialog__body {
      box-sizing: border-box;
      height: 600px;
      background-color: #fff;
      display: flex;
      padding-bottom: 40px;
    }
    video {
      max-width: 100%;
      height: 100%;
      margin: auto;
      background-color: #000;
      // width: 100%;
      // max-width: 100%;
      // margin: $lh20 0;
    }
  }
}
</style>
